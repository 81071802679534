<template>
  <!-- 编辑数字通证配置页面 -->
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="数字通证名称" prop="name">
        <el-select
          v-model="ruleForm.region"
          placeholder="请选择数字通证"
          style="width: 150px"
        >
          <el-option label="TEST" value="TEST"></el-option>
          <el-option label="QJT" value="QJT"></el-option>
          <el-option label="FXB" value="FXB"></el-option>
        </el-select>
      </el-form-item>

      <el-row>
        <el-col :span="6">
          <el-form-item label="提数字通证最小值">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提数字通证最大值">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提数字通证手续费">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="买入最小值">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="卖出证最小值">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="选择数字通证名称" prop="name">
            <el-select
              v-model="ruleForm.region"
              placeholder="请选择数字通证"
              style="width: 150px"
            >
              <el-option label="TEST" value="TEST"></el-option>
              <el-option label="QJT" value="QJT"></el-option>
              <el-option label="FXB" value="FXB"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6">
          <el-form-item label="买入手续费(交易)">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="卖出手续费(交易)">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易手续费类型" prop="name">
            <el-select
              v-model="ruleForm.region"
              placeholder="请选择数字通证"
              style="width: 150px"
            >
              <el-option label="TEST" value="TEST"></el-option>
              <el-option label="QJT" value="QJT"></el-option>
              <el-option label="FXB" value="FXB"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6">
          <el-form-item label="首日涨幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="首日跌幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="非首日涨幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="非首日跌幅最大比例">
            <el-input v-model="model" style="width: 150px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')" style="margin-top:50px"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: "",
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped></style>
